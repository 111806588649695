import React, { useState } from 'react';
import BurgerMenu from './BurgerMenu'; // Make sure to create this component
import SideMenu from './SideMenu'; // Make sure to create this component
import './Header.css';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="App-header">
      <a href="https://artificialgrassrecycle.com/turf_qualifier/">
      <img src="/icon4.png" alt="Icon 2" title="Turf Recycle Centers" className="header-icon1" />
      </a>
      <h1>Lubbock Turf Recycle Center</h1>
      <a href="tel:+18444440469" title="Toll FREE (844)444-0469">
  <img src="/icon2.png" alt="Icon 1" className="header-icon" />
</a>
      <a href="https://wa.me/+523327541028" target="_blank" rel="noopener noreferrer">
      <img src="/icon0.png" alt="whatts app icon " title="Chat with a Turf Specialist on WhatsApp" className="header-icon" />
    </a>
    


      <BurgerMenu onClick={toggleMenu} />
      <SideMenu isOpen={isMenuOpen} />
    </header>
  );
}

export default Header;
